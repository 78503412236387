import React from 'react';
import './App.css';
import PlanetsList from "./planets/PlanetsList";

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <h1 className="title">Planets Of Our Solar System</h1>
            </header>
            <PlanetsList></PlanetsList>
        </div>
    );
}

export default App;
