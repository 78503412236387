import {PlanetDetailsSparse} from "./PlanetDetailsSparse";
import {useState} from "react";
import PlanetDetails from "./PlanetDetails";
import PlanetDetailSection from "./PlanetDetailSection";

function PlanetListItem(planetDetailsSparse: PlanetDetailsSparse, key: string) {
    const [planetDetails, setPlanetDetails] = useState<PlanetDetails | null>(null)
    const [expanded, setExpanded] = useState<boolean>(false)

    const getPlanetDetails = async () => {
        if (expanded || planetDetails != null) {
            setExpanded(!expanded)
            return;
        }

        setExpanded(!expanded)

        const domain = "https://planetsapi.skylardev.com";

        try {
            const getPlanetsUrl = `${domain}/planets/${planetDetailsSparse.id}`;
            const getPlanetsResponse = await fetch(getPlanetsUrl, {headers: {accept: "application/json"}});
            const planetDetail = await getPlanetsResponse.json();
            setPlanetDetails(planetDetail);
        } catch (e: any) {
            console.log(e.message);
        }
    };


    return (
        <button
            className={`planet-details border-gradient ${expanded ? "planet-details-expanded" : "planet-details-collapsed"}`}
            onClick={getPlanetDetails}>

            <div className="planet-details-header">
                <img className="planet-icon" src={planetDetailsSparse.iconUrl}
                     alt={`A flat shaded icon of the planet ${planetDetailsSparse.name}`}/>
                <h3 className="planet-label">{planetDetailsSparse.name}</h3>
            </div>


            <hr className={expanded ? "divider" : "divider-collapsed"}/>
            <PlanetDetailSection planetDetails={planetDetails} expanded={expanded}></PlanetDetailSection>
        </button>
    );
}

export default PlanetListItem;