import PlanetDetails from "./PlanetDetails";

class PlanetDetailSectionProps {
    planetDetails: PlanetDetails | null
    expanded: boolean
}

function PlanetDetailSection(props: PlanetDetailSectionProps) {
    if (!props.expanded) {
        return <div className="planet-details-section planet-details-section-collapsed"></div>
    }

    if (props.planetDetails == null) {
        return <div>Loading...</div>
    }

    return (
        <div className="planet-details-section planet-details-section-expanded">
            <img className="planet-details-image" alt={`The planet ${props.planetDetails.name}`} src={props.planetDetails?.imageUrl}/>
            <div className="planet-details-info">
                <h2 className = "planet-details-info-title">{props.planetDetails?.name + ""} Facts</h2>
                <ul className="planet-details-info-list">
                    <li>Diameter (Kilometres): {props.planetDetails?.diameterInKilometres}</li>
                    <li>Mass (10<sup>24</sup>kg): {props.planetDetails?.mass}</li>
                    <li>Distance from sun (astronomical units): {props.planetDetails?.distanceFromSunInAstronomicalUnits}</li>
                    <li>Number of Moons: {props.planetDetails?.numberOfMoons}</li>
                </ul>
            </div>
        </div>
    )
}

export default PlanetDetailSection;