
import {useEffect, useState} from "react";
import {PlanetDetailsSparse} from "./PlanetDetailsSparse";
import PlanetListItem from "./PlanetListItem";

function PlanetsList() {
    const [planets, setPlanets] = useState<PlanetDetailsSparse[] | null>(null)

    useEffect(() => {
        const getPlanetsList = async () => {
            const domain = "https://planetsapi.skylardev.com";

            try {
                const getPlanetsUrl = `${domain}/planets`;
                const getPlanetsResponse = await fetch(getPlanetsUrl, {headers: {accept: "application/json"}});
                const {planets} = await getPlanetsResponse.json();
                setPlanets(planets);
            } catch (e: any) {
                console.log(e.message)
            }
        };

        if (planets == null){
            getPlanetsList();
        }
    });


    return (
        <div className="planets-list">
            <div className="planets-list-container">
                {planets ? planets.map(
                    (planet, index) => {
                        return (
                            <PlanetListItem name={planet.name} id={planet.id} iconUrl={planet.iconUrl} key={planet.id}></PlanetListItem>
                        )
                    }
                ) : (
                    "Loading..."
                )
                }
            </div>
        </div>
    );
}

export default PlanetsList;